import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SectionTitle from "../components/commons/SectionTitle"
import ColumnsThree from "../components/layouts/container/ColumnsThree"
import Container from "../components/layouts/container/Container"
import Layout from "./../components/layouts/Default"
import P from "../components/typography/P"
import H2 from "../components/typography/H2"
import SvgWebiste from "../components/commons/svgs/SvgWebsite"
import SvgInstagram from "../components/commons/svgs/SvgInstagram"
import SvgFacebook from "../components/commons/svgs/SvgFacebook"
import SvgLinkedIn from "../components/commons/svgs/SvgLinkedIn"
import parse from "html-react-parser"

export default function page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Über uns | Die Summe aller Dinge"
      desc="Wöchentlich unterhalten sich Andy und Johannes über Methoden aus der persönlichen Weiterentwicklung in den verschiedenen Lebensbereichen."
    >
      <Container className="my-8 mmd:my-16">
        <ColumnsThree>
          <div className="col-span-1 mb-6 mmd:mb-0">
            <SectionTitle title="Über uns" className="mb-3" />
            <P>{data.cockpitSingleUberUns.text.value}</P>
          </div>
          <div className="col-span-2 grid grid-cols-1 gap-8">
            {data.allCockpitUeberUns.nodes.map((user, i) => {
              return (
                <div className="grid grid-cols-1 mmd:grid-cols-3 gap-3" key={i}>
                  {/* Image */}
                  <div>
                    {user.website ? (
                      <a
                        href={user.website.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img
                          fluid={user.foto.value.childImageSharp.fluid}
                          className="shadow-xl rounded-xl transform hover:scale-110 duration-300 "
                        />
                      </a>
                    ) : (
                      <Img
                        fluid={user.foto.value.childImageSharp.fluid}
                        className="shadow-xl rounded-xl"
                      />
                    )}
                  </div>
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div>
                        <H2>{user.name.value}</H2>
                      </div>
                      <div className="mb-3 prose">{parse(user.text.value)}</div>
                      {user.website ||
                      user.instagram ||
                      user.facebook ||
                      user.linkedin ? (
                        <ul className="flex space-x-5">
                          {user.website ? (
                            <li>
                              <a
                                href={user.website.value}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">Website</span>
                                <SvgWebiste className="w-5 h-5" />
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {user.instagram ? (
                            <li>
                              <a
                                href={user.instagram.value}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">Instagram</span>
                                <SvgInstagram className="w-5 h-5" />
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {user.facebook ? (
                            <li>
                              <a
                                href={user.facebook.value}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">Facebook</span>
                                <SvgFacebook className="w-5 h-5" />
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                          {user.linked ? (
                            <li>
                              <a
                                href={user.linked.value}
                                className="text-gray-400 hover:text-gray-500"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="sr-only">LinkedIn</span>
                                <SvgLinkedIn className="w-5 h-5" />
                              </a>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </ColumnsThree>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allCockpitUeberUns {
      nodes {
        foto {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        name {
          value
        }
        text {
          value
        }
        facebook {
          value
        }
        linked {
          value
        }
        website {
          value
        }
        instagram {
          value
        }
      }
    }
    cockpitSingleUberUns {
      text {
        value
      }
    }
  }
`
